import React, { useState } from "react";
import BlogPost from "./post";
import BlogGet from "./get";
import BlogPut from "./put";
import BlogDelete from "./delete";

function BlogComp({ modal, handelClose }) {
  const [del, setDel] = useState(false);
  const [delId, setDel_Id] = useState();
  const openDel = (e) => {
    setDel_Id(e.currentTarget.id);
    setDel(true);
  };
  const closeDel = () => setDel(false);

  const [Put, setPut] = useState(false);
  const [PutId, setPut_Id] = useState(0);
  const openPut = (e) => {
    setPut_Id(e.currentTarget.id);
    setPut(true);
  };
  const closePut = () => setPut(false);
  return (
    <>
      <BlogPost modal={modal} handelClose={handelClose} />
      <BlogGet onClickPut={openPut} onClickDelete={openDel} />
      <BlogPut openPut={Put} handleClosePut={closePut} putId={PutId} />
      <BlogDelete openDelete={del} handleCloseDelete={closeDel} delId={delId} />
    </>
  );
}

export default BlogComp;
