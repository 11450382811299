import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./styles.css";
import { Col, Row } from "react-grid-system";
import { Card, Image } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { GetProductss } from "../../../redux/products";

function ProductsGet({ onClickDelete, onClickPut }) {
  const { Meta } = Card;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetProductss());
  }, []);
  const data = useSelector((state) => state.products.getProductss.Data);
  return (
    <>
      <div className="card_wrapper">
        <Row className="row">
          {data.map((elem) => (
            <Col className="col" lg={6}>
              <div className="card_wrap">
                <Card
                  style={{
                    width: "100%",
                    boxShadow: "10px 10px 20px rgba(0, 0, 0, 0.05)",
                  }}
                  cover={
                    <>
                      <Image
                        style={{
                          width: "100%",
                          height: "150px",
                          objectFit: "cover",
                        }}
                        src={elem.img1}
                      />
                      <br />
                      <Image
                        style={{
                          width: "100%",
                          height: "150px",
                          objectFit: "cover",
                          margin: "20px 0",
                        }}
                        src={elem.img2}
                      />
                      <br />
                      <Image
                        style={{
                          width: "100%",
                          height: "150px",
                          objectFit: "cover",
                        }}
                        src={elem.img3}
                      />
                    </>
                  }
                  actions={[
                    <EditOutlined
                      onClick={onClickPut}
                      id={elem.id}
                      key="edit"
                    />,
                    <DeleteOutlined
                      onClick={onClickDelete}
                      id={elem.id}
                      key="delete"
                    />,
                  ]}
                >
                  <Meta title={elem.title_ru} />
                  <br />
                  {/* <Meta title={elem.title_uz} /> */}
                  {/* <Meta title={elem.title_en} /> */}
                  <div className="Decrip">
                    <p>{elem.description_ru}</p>
                    {/* <p>{elem.description_uz}</p> */}
                    {/* <p>{elem.description_en}</p> */}
                  </div>
                  <br />
                  <Meta title={elem.category?.category_name_ru} />
                </Card>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
}

export default ProductsGet;
