import React, { useRef, useEffect, useState } from "react";
import CommonBtn from "../../common/CommonBtn";
import ModalCommon from "../../common/Modal/Modal";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Row, Col } from "react-grid-system";
import styled from "./style.module.css";
import { Spin } from "antd";
import SelectCommon from "../../common/select";
import {
  GetSubservicess,
  PostSubservices,
  PutSubservices,
  PutSubservicesUpload,
  PutSubservicesUpload1,
  PutSubservicesUpload2,
} from "../../../redux/subservices";
import { GetServices } from "../../../redux/services";

function SubservicesPostFormPut({ modal, handleClose, PutId }) {
  const dispatch = useDispatch();
  const titleUz = useRef();
  const titleRu = useRef();
  const titleEn = useRef();
  const DecEn = useRef();
  const DecUz = useRef();
  const DecRu = useRef();
  const DecEn2 = useRef();
  const DecUz2 = useRef();
  const DecRu2 = useRef();
  const DecEn3 = useRef();
  const DecUz3 = useRef();
  const DecRu3 = useRef();
  const [subServices, setsubServices] = useState();
  const IdPut = PutId;

  const dataImage = useSelector(
    (state) => state.subservices.uploadPutSubservices.data
  );
  const dataImage1 = useSelector(
    (state) => state.subservices.uploadPutSubservices1.data
  );
  const dataImage2 = useSelector(
    (state) => state.subservices.uploadPutSubservices2.data
  );

  const HandelOnChange = (e) => {
    dispatch(PutSubservicesUpload(e));
  };
  const HandelOnChange1 = (e) => {
    dispatch(PutSubservicesUpload1(e));
  };
  const HandelOnChange2 = (e) => {
    dispatch(PutSubservicesUpload2(e));
  };

  useEffect(() => {
    dispatch(GetServices());
  }, []);
  const services = useSelector((state) => state.services.getServices.Data);
  const options = [];
  services.map((elem) =>
    options.push({
      value: elem.id,
      label: elem.services_name_ru,
    })
  );

  const HandleSubmit = async (e) => {
    e.preventDefault();
    const body = {
      title_uz: titleUz.current.value,
      title_ru: titleRu.current.value,
      title_en: titleEn.current.value,
      description_uz: DecUz.current.value,
      description_en: DecEn.current.value,
      description_ru: DecRu.current.value,
      description_uz2: DecUz2.current.value,
      description_en2: DecEn2.current.value,
      description_ru2: DecRu2.current.value,
      description_uz3: DecUz3.current.value,
      description_en3: DecEn3.current.value,
      description_ru3: DecRu3.current.value,
      img1: dataImage,
      img2: dataImage1,
      img3: dataImage2,
      services: subServices,
    };
    await dispatch(PutSubservices({ body, id: IdPut }));
    dispatch(GetSubservicess());
    window.location.reload();
  };
  const Loading = useSelector(
    (state) => state.subservices.uploadPutSubservices.Loading
  );
  const Loading1 = useSelector(
    (state) => state.subservices.uploadPutSubservices1.Loading
  );
  const Loading2 = useSelector(
    (state) => state.subservices.uploadPutSubservices2.Loading
  );
  const Success = useSelector(
    (state) => state.subservices.uploadPutSubservices.Success
  );
  const Success1 = useSelector(
    (state) => state.subservices.uploadPutSubservices1.Success
  );
  const Success2 = useSelector(
    (state) => state.subservices.uploadPutSubservices2.Success
  );
  const data = useSelector((state) => state.subservices.getSubservicess.Data);
  return (
    <ModalCommon width={550} open={modal} handleClose={handleClose}>
      <>
        {data.map((elem) =>
          elem.id == PutId ? (
            <>
              <form onSubmit={HandleSubmit}>
                <div className={styled.Wrapper}>
                  <h3>Изменить Подуслуги</h3>
                  <div className={styled.input_wrap}>
                    <div className={styled.scrool}>
                      <Row className={styled.row}>
                        <Col className={styled.col} lg={6}>
                          {Success ? (
                            <>
                              <div className={styled.spinsSecond}>
                                <ion-icon name="checkmark-outline"></ion-icon>
                              </div>
                            </>
                          ) : (
                            <>
                              {Loading ? (
                                <div className={styled.spins}>
                                  <Spin size="large" />
                                </div>
                              ) : (
                                <>
                                  <input
                                    type="file"
                                    id="file"
                                    onChange={HandelOnChange}
                                  />
                                  <label for="file" class="custom-file-upload">
                                    <span className={styled.spandownload}>
                                      <ion-icon name="cloud-download-outline"></ion-icon>
                                    </span>
                                  </label>
                                </>
                              )}
                            </>
                          )}
                        </Col>
                        <Col className={styled.col} lg={6}>
                          {Success1 ? (
                            <>
                              <div className={styled.spinsSecond}>
                                <ion-icon name="checkmark-outline"></ion-icon>
                              </div>
                            </>
                          ) : (
                            <>
                              {Loading1 ? (
                                <div className={styled.spins}>
                                  <Spin size="large" />
                                </div>
                              ) : (
                                <>
                                  <input
                                    type="file"
                                    id="file"
                                    onChange={HandelOnChange1}
                                  />
                                  <label for="file" class="custom-file-upload">
                                    <span className={styled.spandownload}>
                                      <ion-icon name="cloud-download-outline"></ion-icon>
                                    </span>
                                  </label>
                                </>
                              )}
                            </>
                          )}
                        </Col>
                        <Col className={styled.cols} lg={12}>
                          {Success2 ? (
                            <>
                              <div className={styled.spinsSecond}>
                                <ion-icon name="checkmark-outline"></ion-icon>
                              </div>
                            </>
                          ) : (
                            <>
                              {Loading2 ? (
                                <div className={styled.spins}>
                                  <Spin size="large" />
                                </div>
                              ) : (
                                <>
                                  <input
                                    type="file"
                                    id="file"
                                    onChange={HandelOnChange2}
                                  />
                                  <label for="file" class="custom-file-upload">
                                    <span className={styled.spandownload}>
                                      <ion-icon name="cloud-download-outline"></ion-icon>
                                    </span>
                                  </label>
                                </>
                              )}
                            </>
                          )}
                        </Col>
                        <Col className={styled.col} lg={6}>
                          <input
                            type="text"
                            placeholder={elem.title_uz}
                            defaultValue={elem.title_uz}
                            ref={titleUz}
                          />
                        </Col>
                        <Col className={styled.col} lg={6}>
                          <input
                            type="text"
                            placeholder={elem.title_ru}
                            defaultValue={elem.title_ru}
                            ref={titleRu}
                          />
                        </Col>
                        <Col className={styled.col} lg={12}>
                          <input
                            type="text"
                            placeholder={elem.title_en}
                            defaultValue={elem.title_en}
                            ref={titleEn}
                          />
                        </Col>

                        {/* //des */}
                        <Col className={styled.col} lg={6}>
                          <input
                            type="text"
                            placeholder={elem.description_uz}
                            defaultValue={elem.description_uz}
                            ref={DecUz}
                          />
                        </Col>
                        <Col className={styled.col} lg={6}>
                          <input
                            type="text"
                            placeholder={elem.description_ru}
                            defaultValue={elem.description_ru}
                            ref={DecRu}
                          />
                        </Col>
                        <Col className={styled.col} lg={12}>
                          <input
                            type="text"
                            placeholder={elem.description_en}
                            defaultValue={elem.description_en}
                            ref={DecEn}
                          />
                        </Col>

                        {/* //des2 */}
                        <Col className={styled.col} lg={6}>
                          <input
                            type="text"
                            placeholder={elem.description_uz2}
                            defaultValue={elem.description_uz2}
                            ref={DecUz2}
                          />
                        </Col>
                        <Col className={styled.col} lg={6}>
                          <input
                            type="text"
                            placeholder={elem.description_ru2}
                            defaultValue={elem.description_ru2}
                            ref={DecRu2}
                          />
                        </Col>
                        <Col className={styled.col} lg={12}>
                          <input
                            type="text"
                            placeholder={elem.description_en2}
                            defaultValue={elem.description_en2}
                            ref={DecEn2}
                          />
                        </Col>

                        {/* //des3 */}

                        <Col className={styled.col} lg={6}>
                          <input
                            type="text"
                            placeholder={elem.description_uz3}
                            defaultValue={elem.description_uz3}
                            ref={DecUz3}
                          />
                        </Col>
                        <Col className={styled.col} lg={6}>
                          <input
                            type="text"
                            placeholder={elem.description_uz3}
                            defaultValue={elem.description_ru3}
                            ref={DecRu3}
                          />
                        </Col>
                        <Col className={styled.col} lg={12}>
                          <input
                            type="text"
                            placeholder={elem.description_en3}
                            defaultValue={elem.description_en3}
                            ref={DecEn3}
                          />
                        </Col>
                        <Col lg={12}>
                          <div className="selects">
                            <SelectCommon
                              onChange={(e) => setsubServices(e)}
                              placeholder="Select"
                              options={options}
                            />
                          </div>
                        </Col>
                      </Row>
                      <CommonBtn
                        type="submit"
                        style={{
                          margin: "20px auto 0 auto",
                          padding: "12px 40px",
                          border: "2px solid #fff",
                        }}
                      >
                        Добавить
                      </CommonBtn>
                    </div>
                  </div>
                </div>
              </form>
            </>
          ) : null
        )}
      </>
    </ModalCommon>
  );
}

export default SubservicesPostFormPut;
