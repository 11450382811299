import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../../utils/api";
export const RequestGet = createAsyncThunk("Request/Get", () => {
  return axios.get(`${API_URL}/form`).then((response) => response.data);
});
export const RequestDelete = createAsyncThunk("Request/Delete", async (id) => {
  return await axios.delete(`${API_URL}/form/${id}`).then((res) => res);
});
const RequestSlice = createSlice({
  name: "Request",
  initialState: {
    requestGet: {
      loading: false,
      data: [],
      error: false,
      success: false,
    },

    RequestDelete: {
      Success: false,
      Error: false,
      loading: false,
    },
  },
  extraReducers: {
    // get
    [RequestGet.pending]: (state, action) => {
      state.requestGet.loading = true;
    },
    [RequestGet.fulfilled]: (state, action) => {
      state.requestGet.loading = false;
      state.requestGet.success = true;
      state.requestGet.data = action.payload;
      state.requestGet.error = false;
    },
    [RequestGet.rejected]: (state, action) => {
      state.requestGet.loading = false;
      state.requestGet.error = true;
      state.requestGet.success = false;
    },
    // delete
    [RequestDelete.pending]: (state, action) => {
      state.RequestDelete.loadingDelete = true;
    },
    [RequestDelete.fulfilled]: (state, action) => {
      state.RequestDelete.loading = false;
      state.RequestDelete.Success = true;
      state.RequestDelete.Error = false;
    },
    [RequestDelete.rejected]: (state, action) => {
      state.RequestDelete.loading = false;
      state.RequestDelete.Error = true;
      state.RequestDelete.Success = false;
    },
  },
});

export const {} = RequestSlice.actions;
export default RequestSlice.reducer;
