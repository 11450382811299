import { lazy } from "react";
import Cookies from "universal-cookie";
import Requests from "../pages/requests";
import Category from "../pages/category";
import Services from "../pages/services";
import Products from "../pages/products";
import SubServices from "../pages/subServices";
import Blog from "../pages/blog";

const Login = lazy(() => import("./../pages/login/index"));
const AddAdmin = lazy(() => import("./../pages/add_admin/index"));
const cookie = new Cookies();

export const RouterData = [
  {
    id: 1,
    path: "/",
    component: cookie.get("token") ? null : <Login />,
  },
  {
    id: 2,
    path: "/adminadd",
    component: cookie.get("token") ? <AddAdmin /> : null,
  },
  {
    id: 3,
    path: "/requests",
    component: cookie.get("token") ? <Requests /> : null,
  },
  {
    id: 4,
    path: "/category",
    component: cookie.get("token") ? <Category /> : null,
  },
  {
    id: 4,
    path: "/services",
    component: cookie.get("token") ? <Services /> : null,
  },
  {
    id: 5,
    path: "/products",
    component: cookie.get("token") ? <Products /> : null,
  },
  {
    id: 6,
    path: "/subservices",
    component: cookie.get("token") ? <SubServices /> : null,
  },
  {
    id: 6,
    path: "/blog",
    component: cookie.get("token") ? <Blog /> : null,
  },
];
