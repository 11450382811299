import React, { useState } from "react";
import SubservicesPostForm from "./post";
import SubServicesGet from "./get";
import DeleteSubServices from "./delete";
import SubservicesPostFormPut from "./put";

function SubservicesComp({ modal, HandelClose }) {
  const [del, setdel] = useState(false);
  const [del_id, setdel_Id] = useState();
  const HandelOpendel = (e) => {
    setdel_Id(e.currentTarget.id);
    setdel(true);
  };
  const HandelCloseDel = () => setdel(false);

  const [Put, setPut] = useState(false);
  const [Put_id, setPut_Id] = useState();
  const HanPutOpenPut = (e) => {
    setPut_Id(e.currentTarget.id);
    setPut(true);
  };
  const HanPutClosePut = () => setPut(false);
  return (
    <>
      <SubservicesPostForm modal={modal} handleClose={HandelClose} />
      <SubServicesGet
        onClickDelete={HandelOpendel}
        onClickPut={HanPutOpenPut}
      />
      <DeleteSubServices
        openDelete={del}
        handleCloseDelete={HandelCloseDel}
        del_id={del_id}
      />
      <SubservicesPostFormPut
        modal={Put}
        handleClose={HanPutClosePut}
        PutId={Put_id}
      />
    </>
  );
}

export default SubservicesComp;
