import React, { useState } from "react";
import Sidebar from "../../components/sidebar";
import { WrapperContainer } from "../../style-App";
import HeaderTopCommon from "../../components/common/HeaderTop";
import ServicesComp from "../../components/services";

function Services() {
  const [modal, setModal] = useState(false);
  const HandelOpen = () => setModal(true);
  const HandelClose = () => setModal(false);
  return (
    <>
      <Sidebar>
        <WrapperContainer>
          <HeaderTopCommon
            title={"Услуги"}
            textBtn={"Добавить услуги"}
            onClick={HandelOpen}
          />
          <ServicesComp modal={modal} handelClose={HandelClose} />
        </WrapperContainer>
      </Sidebar>
    </>
  );
}

export default Services;
