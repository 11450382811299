import React, { useEffect, useRef } from "react";
import ModalCommon from "../../common/Modal/Modal";
import styled from "./style.module.css";
import { Col, Row } from "react-grid-system";
import CommonBtn from "../../common/CommonBtn";
import { Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  GetServices,
  PutServices,
  PutServicesUpload,
} from "../../../redux/services";

function ServicesPut({ openPut, handleClosePut, putId }) {
  const titleUz = useRef();
  const titleEn = useRef();
  const titleRu = useRef();
  const IDpUT = putId;
  const dispatch = useDispatch();
  const dataImage = useSelector(
    (state) => state.services.uploadPutServices.data
  );
  const data = useSelector((state) => state.services.getServices.Data);
  const HandelOnChange = (e) => {
    dispatch(PutServicesUpload(e));
  };
  const HandleSubmitPut = async (e) => {
    e.preventDefault();
    const body = {
      services_name_uz: titleUz.current.value,
      services_name_en: titleEn.current.value,
      services_name_ru: titleRu.current.value,
      image: dataImage,
    };
    await dispatch(PutServices({ body, id: IDpUT }));
    dispatch(GetServices());
    window.location.reload();
  };

  const Loading = useSelector(
    (state) => state.services.uploadPutServices.Loading
  );
  const Success = useSelector(
    (state) => state.services.uploadPutServices.Success
  );
  console.log(putId);
  return (
    <>
      <ModalCommon open={openPut} handleClose={handleClosePut}>
        {data.map((elem, index) =>
          elem.id == putId ? (
            <>
              <form onSubmit={HandleSubmitPut} className={styled.Wrapper}>
                <h3>Добавить категорию</h3>
                <div className={styled.input_wrap}>
                  <div className={styled.scrool}>
                    <Row className={styled.row}>
                      <Col className={styled.col} lg={12}>
                        {Success ? (
                          <>
                            <div className={styled.spinsSecond}>
                              <ion-icon name="checkmark-outline"></ion-icon>
                            </div>
                          </>
                        ) : (
                          <>
                            {Loading ? (
                              <div className={styled.spins}>
                                <Spin size="large" />
                              </div>
                            ) : (
                              <>
                                <input
                                  type="file"
                                  id="file"
                                  onChange={HandelOnChange}
                                />
                                <label for="file" class="custom-file-upload">
                                  <span className={styled.spandownload}>
                                    <ion-icon name="cloud-download-outline"></ion-icon>
                                  </span>
                                </label>
                              </>
                            )}
                          </>
                        )}
                      </Col>
                      <Col className={styled.cols} lg={6}>
                        <input
                          type="text"
                          placeholder={elem.services_name_uz}
                          defaultValue={elem.services_name_uz}
                          ref={titleUz}
                        />
                      </Col>
                      <Col className={styled.cols} lg={6}>
                        <input
                          type="text"
                          placeholder={elem.services_name_ru}
                          defaultValue={elem.services_name_ru}
                          ref={titleRu}
                        />
                      </Col>
                      <Col className={styled.col} lg={12}>
                        <input
                          type="text"
                          placeholder={elem.services_name_en}
                          defaultValue={elem.services_name_en}
                          ref={titleEn}
                        />
                      </Col>
                    </Row>
                    <CommonBtn
                      type="submit"
                      style={{
                        margin: "20px auto 0 auto",
                        padding: "12px 40px",
                        border: "2px solid #fff",
                      }}
                    >
                      Добавить
                    </CommonBtn>
                  </div>
                </div>
              </form>
            </>
          ) : null
        )}
      </ModalCommon>
    </>
  );
}

export default ServicesPut;
