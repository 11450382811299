import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../../utils/api";

export const PostBlog = createAsyncThunk("Blog/post", async (body) => {
  return axios.post(`${API_URL}/blogs`, body).then((res) => res);
});
export const GetBlog = createAsyncThunk("Blog/get", async () => {
  return await axios.get(`${API_URL}/blogs`).then((response) => response.data);
});
export const DeleteBlog = createAsyncThunk("Blog/delete", async (id) => {
  return await axios
    .delete(`${API_URL}/blogs/${id}`)
    .then((response) => response.data);
});
export const PutBlog = createAsyncThunk("Blog/put", async ({ body, id }) => {
  return await axios
    .put(`${API_URL}/blogs/${id}`, body)
    .then((response) => console.log(response));
});
export const UploadBlog = createAsyncThunk("Blog/upload", async (e) => {
  const formData = new FormData();
  formData.append("file", e.target.files[0]);
  formData.append("upload_preset", "br3m8rzo");
  try {
    return await axios
      .post("https://api.cloudinary.com/v1_1/dibvlfm8v/upload", formData)
      .then((response) => response?.data.secure_url);
  } catch (error) {
    return error;
  }
});
export const PutBlogUpload = createAsyncThunk("Blog/uploadPut", async (e) => {
  const formData = new FormData();
  formData.append("file", e.target.files[0]);
  formData.append("upload_preset", "br3m8rzo");
  try {
    return await axios
      .post("https://api.cloudinary.com/v1_1/dibvlfm8v/upload", formData)
      .then((response) => response?.data.secure_url);
  } catch (error) {
    return error;
  }
});
const BlogSlice = createSlice({
  name: "Blog",
  initialState: {
    postBlog: {
      Error: false,
      Loading: false,
      Success: false,
    },
    getBlog: {
      Error: false,
      Loading: false,
      Success: false,
      Data: [],
    },
    deleteBlog: {
      Error: false,
      Loading: false,
      Success: false,
    },
    putBlog: {
      Error: false,
      Loading: false,
      Success: false,
    },
    uploadBlog: {
      Error: false,
      Loading: false,
      Success: false,
      data: "",
    },
    uploadPutBlog: {
      Error: false,
      Loading: false,
      Success: false,
      data: "",
    },
  },
  extraReducers: {
    [PostBlog.pending]: (state, action) => {
      state.postBlog.Loading = true;
    },
    [PostBlog.fulfilled]: (state, action) => {
      state.postBlog.Error = false;
      state.postBlog.Success = true;
      state.postBlog.Loading = false;
    },
    [PostBlog.rejected]: (state, action) => {
      state.postBlog.Error = true;
      state.postBlog.Success = false;
      state.postBlog.Loading = false;
    },
    [GetBlog.pending]: (state, action) => {
      state.getBlog.Loading = true;
    },
    [GetBlog.fulfilled]: (state, action) => {
      state.getBlog.Error = false;
      state.getBlog.Success = true;
      state.getBlog.Loading = false;
      state.getBlog.Data = action.payload;
    },
    [GetBlog.rejected]: (state, action) => {
      state.getBlog.Error = true;
      state.getBlog.Success = false;
      state.getBlog.Loading = false;
      state.getBlog.Data = [];
    },
    [DeleteBlog.pending]: (state, action) => {
      state.deleteBlog.Loading = true;
    },
    [DeleteBlog.fulfilled]: (state, action) => {
      state.deleteBlog.Error = false;
      state.deleteBlog.Success = true;
      state.deleteBlog.Loading = false;
    },
    [DeleteBlog.rejected]: (state, action) => {
      state.deleteBlog.Error = true;
      state.deleteBlog.Success = false;
      state.deleteBlog.Loading = false;
    },
    [PutBlog.pending]: (state, action) => {
      state.putBlog.Loading = true;
    },
    [PutBlog.fulfilled]: (state, action) => {
      state.putBlog.Error = false;
      state.putBlog.Success = true;
      state.putBlog.Loading = false;
    },
    [PutBlog.rejected]: (state, action) => {
      state.putBlog.Error = true;
      state.putBlog.Success = false;
      state.putBlog.Loading = false;
    },
    [UploadBlog.pending]: (state, action) => {
      state.uploadBlog.Loading = true;
    },
    [UploadBlog.fulfilled]: (state, action) => {
      state.uploadBlog.Error = false;
      state.uploadBlog.Success = true;
      state.uploadBlog.Loading = false;
      state.uploadBlog.data = action.payload;
    },
    [UploadBlog.rejected]: (state, action) => {
      state.uploadBlog.Error = true;
      state.uploadBlog.Success = false;
      state.uploadBlog.Loading = false;
    },
    [PutBlogUpload.pending]: (state, action) => {
      state.uploadPutBlog.Loading = true;
    },
    [PutBlogUpload.fulfilled]: (state, action) => {
      state.uploadPutBlog.Error = false;
      state.uploadPutBlog.Success = true;
      state.uploadPutBlog.Loading = false;
      state.uploadPutBlog.data = action.payload;
    },
    [PutBlogUpload.rejected]: (state, action) => {
      state.uploadPutBlog.Error = true;
      state.uploadPutBlog.Success = false;
      state.uploadPutBlog.Loading = false;
    },
  },
});

export default BlogSlice.reducer;
