import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RequestGet } from "../../../redux/requests";
import TableCommon from "../../common/table";

function GetRequest({ HandelOpen }) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(RequestGet());
  }, []);
  const requestget = useSelector((state) => state.requests);
  const rows = requestget.requestGet.data;

  const data = [];
  rows.map((elem, index) => {
    data.push({
      data: index + 1,
      key: elem.id,
      Имя: elem.name,
      Номертелефона: elem.phone_number,
      Описание: elem.comentary,
      Действие: (
        <div className="btn-wrap">
          <button onClick={HandelOpen} id={elem.id}>
            <i id={elem.id} class="bx bxs-trash"></i>
          </button>
        </div>
      ),
    });
  });
  const columns = [
    {
      title: "№",
      dataIndex: "data",
      key: "data",
      width: 50,
      align: "center",
    },
    {
      title: "Имя",
      dataIndex: "Имя",
      key: "Имя",
    },
    {
      title: "Номер телефона",
      dataIndex: "Номертелефона",
      key: "Номертелефона",
    },
    {
      title: "Описание",
      dataIndex: "Описание",
      key: "Описание",
    },
    {
      title: "Действие",
      dataIndex: "Действие",
      key: "Действие",
    },
  ];
  return (
    <>
      <TableCommon
        bordered
        data={data}
        columns={columns}
        pagination={false}
        scroll={{
          y: 330,
        }}
      />
    </>
  );
}

export default GetRequest;
