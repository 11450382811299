import React, { useState } from "react";
import ServicesPost from "./post";
import ServicesGet from "./get";
import ServicesDelete from "./delete";
import ServicesPut from "./put";

function ServicesComp({ modal, handelClose }) {
  const [del, setDel] = useState(false);
  const [delId, setDel_Id] = useState();
  const openDel = (e) => {
    setDel_Id(e.currentTarget.id);
    setDel(true);
  };
  const closeDel = () => setDel(false);

  const [Put, setPut] = useState(false);
  const [PutId, setPut_Id] = useState(0);
  const openPut = (e) => {
    setPut_Id(e.currentTarget.id);
    setPut(true);
  };
  const closePut = () => setPut(false);
  return (
    <>
      <ServicesPost modal={modal} handelClose={handelClose} />
      <ServicesGet onClickDelete={openDel} onClickPut={openPut} />
      <ServicesDelete
        openDelete={del}
        handleCloseDelete={closeDel}
        delId={delId}
      />
      <ServicesPut openPut={Put} handleClosePut={closePut} putId={PutId} />
    </>
  );
}

export default ServicesComp;
