import React from "react";
import ModalCommon from "../../common/Modal/Modal";
import styled from "./style.module.css";
import { Col, Row } from "react-grid-system";
import { useDispatch, useSelector } from "react-redux";
import { DeleteBlog, GetBlog } from "../../../redux/Blog";

function BlogDelete({ handleCloseDelete, openDelete, delId }) {
  const delI = delId;
  const disptach = useDispatch();
  const HandleDelete = async (e) => {
    e.preventDefault();
    await disptach(DeleteBlog(delI));
    disptach(GetBlog());
  };
  const Success = useSelector((state) => state.blog.deleteBlog.Success);
  if (Success) {
    handleCloseDelete();
  }

  return (
    <>
      <ModalCommon
        width={340}
        open={openDelete}
        handleClose={handleCloseDelete}
      >
        <div className={styled.Wrapper}>
          <h3>Вы действительно хотите удалить блог?</h3>
          <p>
            При удалений категорий вся информация принадлежащая данной категории
            будут удалены безвозратно
          </p>
          <div className={styled.Buttons}>
            <Row className={styled.row}>
              <Col className={styled.col} lg={6}>
                <button onClick={HandleDelete}>
                  <i class="bx bx-check"></i> Да
                </button>
              </Col>
              <Col className={styled.col} lg={6}>
                <button onClick={handleCloseDelete}>
                  <i class="bx bx-x"></i> Нет
                </button>
              </Col>
            </Row>
          </div>
        </div>
      </ModalCommon>
    </>
  );
}

export default BlogDelete;
