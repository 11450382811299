import React from "react";
import ModalCommon from "../../common/Modal/Modal";
import { Col, Row } from "react-grid-system";
import styled from "./style.module.css";

function DeleteRequest({ openDelete, handleCloseDelete, HandleDelete }) {
  return (
    <>
      <ModalCommon
        width={360}
        open={openDelete}
        handleClose={handleCloseDelete}
      >
        <div className={styled.Wrapper}>
          <h3>Вы уверены, что хотите удалить данные?</h3>
          <div className={styled.Buttons}>
            <Row className={styled.row}>
              <Col className={styled.col} lg={6}>
                <button onClick={HandleDelete}>
                  <i class="bx bx-check"></i> Да
                </button>
              </Col>
              <Col className={styled.col} lg={6}>
                <button onClick={handleCloseDelete}>
                  <i class="bx bx-x"></i> Нет
                </button>
              </Col>
            </Row>
          </div>
        </div>
      </ModalCommon>
    </>
  );
}

export default DeleteRequest;
