import React, { useState } from "react";
import Sidebar from "../../components/sidebar";
import { WrapperContainer } from "../../style-App";
import HeaderTopCommon from "../../components/common/HeaderTop";
import ProductsComp from "../../components/products";
import SubservicesComp from "../../components/subservices";

function SubServices() {
  const [modal, setModal] = useState(false);

  const HandelOpen = (e) => setModal(true);
  const HandelClose = (e) => setModal(false);
  return (
    <>
      <Sidebar>
        <WrapperContainer>
          <HeaderTopCommon
            title={"Подуслуги"}
            textBtn={"Добавить подуслуги"}
            onClick={HandelOpen}
          />
          <SubservicesComp modal={modal} HandelClose={HandelClose} />
        </WrapperContainer>
      </Sidebar>
    </>
  );
}

export default SubServices;
