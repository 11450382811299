import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetServices } from "../../../redux/services";
import "./styles.css";
import { Col, Row } from "react-grid-system";
import { Card, Image } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

function ServicesGet({ onClickDelete, onClickPut, selectId }) {
  const { Meta } = Card;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetServices());
  }, []);
  const data = useSelector((state) => state.services.getServices.Data);
  return (
    <>
      <div className="card_wrapper">
        <Row className="row">
          {data.map((elem) => (
            <Col className="col" lg={4}>
              <div className="card_wrap">
                <Card
                  style={{
                    width: "100%",
                    boxShadow: "10px 10px 20px rgba(0, 0, 0, 0.05)",
                  }}
                  cover={
                    <>
                      <Image
                        style={{
                          width: "100%",
                          height: "150px",
                          objectFit: "cover",
                        }}
                        src={elem.image}
                      />
                    </>
                  }
                  actions={[
                    <EditOutlined
                      onClick={onClickPut}
                      id={elem.id}
                      key="edit"
                    />,
                    <DeleteOutlined
                      onClick={onClickDelete}
                      id={elem.id}
                      key="delete"
                    />,
                  ]}
                >
                  <Meta title={elem.services_name_ru} />
                  {/* <Meta title={elem.services_name_uz} /> */}
                  {/* <Meta title={elem.services_name_en} /> */}
                </Card>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
}

export default ServicesGet;
