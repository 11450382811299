import { useDispatch } from "react-redux";
import ModalCommon from "../../common/Modal/Modal";
import styled from "./style.module.css";
import { Row, Col } from "react-grid-system";
import { CategoryDelete, CategoryGet } from "../../../redux/category";

function DeleteCategory({ openDelete, handleCloseDelete, del_id }) {
  const dispatch = useDispatch();
  const delId = del_id;
  const HandleDelete = async (e) => {
    e.preventDefault();
    await dispatch(CategoryDelete(delId));
    dispatch(CategoryGet());
    handleCloseDelete();
  };
  return (
    <>
      <ModalCommon
        width={340}
        open={openDelete}
        handleClose={handleCloseDelete}
      >
        <div className={styled.Wrapper}>
          <h3>Вы действительно хотите удалить категорию?</h3>
          <p>
            При удалений категорий вся информация принадлежащая данной категории
            будут удалены безвозратно
          </p>
          <div className={styled.Buttons}>
            <Row className={styled.row}>
              <Col className={styled.col} lg={6}>
                <button onClick={HandleDelete}>
                  <i class="bx bx-check"></i> Да
                </button>
              </Col>
              <Col className={styled.col} lg={6}>
                <button onClick={handleCloseDelete}>
                  <i class="bx bx-x"></i> Нет
                </button>
              </Col>
            </Row>
          </div>
        </div>
      </ModalCommon>
    </>
  );
}
export default DeleteCategory;
