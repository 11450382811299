import React, { useRef, useEffect } from "react";
import CommonBtn from "../../common/CommonBtn";
import ModalCommon from "../../common/Modal/Modal";
import { useDispatch } from "react-redux";
import { CategoryAdd, CategoryGet } from "../../../redux/category";
import { useSelector } from "react-redux";
import { Row, Col } from "react-grid-system";
import styled from "./style.module.css";

function CategoryAddForm({ modal, handleClose }) {
  const dispatch = useDispatch();
  const titleUz = useRef();
  const titleRu = useRef();
  const titleEn = useRef();
  const categoryAdd = useSelector((state) => state.category);
  useEffect(() => {
    dispatch(CategoryGet());
  }, []);
  const HandleSubmit = async (e) => {
    e.preventDefault();

    const body = {
      category_name_uz: titleUz.current.value,
      category_name_ru: titleRu.current.value,
      category_name_en: titleEn.current.value,
    };
    await dispatch(CategoryAdd(body));
    dispatch(CategoryGet());
    window.location.reload();
  };
  return (
    <ModalCommon width={550} open={modal} handleClose={handleClose}>
      <>
        <form onSubmit={HandleSubmit}>
          <div className={styled.Wrapper}>
            <h3>Добавить категорию</h3>
            <div className={styled.input_wrap}>
              <div className={styled.scrool}>
                <Row className={styled.row}>
                  <Col className={styled.col} lg={6}>
                    <input
                      type="text"
                      placeholder="Категория узб..."
                      required
                      ref={titleUz}
                    />
                  </Col>
                  <Col className={styled.col} lg={6}>
                    <input
                      type="text"
                      placeholder="Категория русский..."
                      required
                      ref={titleRu}
                    />
                  </Col>
                  <Col className={styled.col} lg={12}>
                    <input
                      type="text"
                      placeholder="Категория англ..."
                      required
                      ref={titleEn}
                    />
                  </Col>
                </Row>
                <CommonBtn
                  type="submit"
                  style={{
                    margin: "20px auto 0 auto",
                    padding: "12px 40px",
                    border: "2px solid #fff",
                  }}
                >
                  Добавить
                </CommonBtn>
              </div>
            </div>
          </div>
        </form>
      </>
    </ModalCommon>
  );
}

export default CategoryAddForm;
