import React, { useState } from "react";
import ProductAddForm from "./post";
import ProductsGet from "./get";
import DeleteProduct from "./delete";
import ProductPutForm from "./put";

function ProductsComp({ modal, handelClose }) {
  const [del, setdel] = useState(false);
  const [del_id, setdel_Id] = useState();
  const HandelOpendel = (e) => {
    setdel_Id(e.currentTarget.id);
    setdel(true);
  };
  const HandelCloseDel = () => setdel(false);

  const [Put, setPut] = useState(false);
  const [Put_id, setPut_Id] = useState();
  const HanPutOpenPut = (e) => {
    setPut_Id(e.currentTarget.id);
    setPut(true);
  };
  const HanPutClosePut = () => setPut(false);
  return (
    <>
      <ProductAddForm modal={modal} handleClose={handelClose} />
      <ProductsGet onClickDelete={HandelOpendel} onClickPut={HanPutOpenPut} />
      <DeleteProduct
        openDelete={del}
        handleCloseDelete={HandelCloseDel}
        del_id={del_id}
      />
      <ProductPutForm modal={Put} handleClose={HanPutClosePut} putId={Put_id} />
    </>
  );
}

export default ProductsComp;
