import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../../utils/api";

export const PostService = createAsyncThunk("Services/post", async (body) => {
  return axios.post(`${API_URL}/services`, body).then((res) => res);
});
export const GetServices = createAsyncThunk("Services/get", async () => {
  return await axios
    .get(`${API_URL}/services`)
    .then((response) => response.data);
});
export const DeleteServices = createAsyncThunk(
  "Services/delete",
  async (id) => {
    return await axios
      .delete(`${API_URL}/services/${id}`)
      .then((response) => response.data);
  }
);
export const PutServices = createAsyncThunk(
  "Services/put",
  async ({ body, id }) => {
    return await axios
      .put(`${API_URL}/services/${id}`, body)
      .then((response) => console.log(response));
  }
);
export const UploadServices = createAsyncThunk("Services/upload", async (e) => {
  const formData = new FormData();
  formData.append("file", e.target.files[0]);
  formData.append("upload_preset", "br3m8rzo");
  try {
    return await axios
      .post("https://api.cloudinary.com/v1_1/dibvlfm8v/upload", formData)
      .then((response) => response?.data.secure_url);
  } catch (error) {
    return error;
  }
});
export const PutServicesUpload = createAsyncThunk(
  "Services/uploadPut",
  async (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("upload_preset", "br3m8rzo");
    try {
      return await axios
        .post("https://api.cloudinary.com/v1_1/dibvlfm8v/upload", formData)
        .then((response) => response?.data.secure_url);
    } catch (error) {
      return error;
    }
  }
);
const ServicesSlice = createSlice({
  name: "Services",
  initialState: {
    postService: {
      Error: false,
      Loading: false,
      Success: false,
    },
    getServices: {
      Error: false,
      Loading: false,
      Success: false,
      Data: [],
    },
    deleteServices: {
      Error: false,
      Loading: false,
      Success: false,
    },
    putServices: {
      Error: false,
      Loading: false,
      Success: false,
    },
    uploadServices: {
      Error: false,
      Loading: false,
      Success: false,
      data: "",
    },
    uploadPutServices: {
      Error: false,
      Loading: false,
      Success: false,
      data: "",
    },
  },
  extraReducers: {
    [PostService.pending]: (state, action) => {
      state.postService.Loading = true;
    },
    [PostService.fulfilled]: (state, action) => {
      state.postService.Error = false;
      state.postService.Success = true;
      state.postService.Loading = false;
    },
    [PostService.rejected]: (state, action) => {
      state.postService.Error = true;
      state.postService.Success = false;
      state.postService.Loading = false;
    },
    [GetServices.pending]: (state, action) => {
      state.getServices.Loading = true;
    },
    [GetServices.fulfilled]: (state, action) => {
      state.getServices.Error = false;
      state.getServices.Success = true;
      state.getServices.Loading = false;
      state.getServices.Data = action.payload;
    },
    [GetServices.rejected]: (state, action) => {
      state.getServices.Error = true;
      state.getServices.Success = false;
      state.getServices.Loading = false;
      state.getServices.Data = [];
    },
    [DeleteServices.pending]: (state, action) => {
      state.deleteServices.Loading = true;
    },
    [DeleteServices.fulfilled]: (state, action) => {
      state.deleteServices.Error = false;
      state.deleteServices.Success = true;
      state.deleteServices.Loading = false;
    },
    [DeleteServices.rejected]: (state, action) => {
      state.deleteServices.Error = true;
      state.deleteServices.Success = false;
      state.deleteServices.Loading = false;
    },
    [PutServices.pending]: (state, action) => {
      state.putServices.Loading = true;
    },
    [PutServices.fulfilled]: (state, action) => {
      state.putServices.Error = false;
      state.putServices.Success = true;
      state.putServices.Loading = false;
    },
    [PutServices.rejected]: (state, action) => {
      state.putServices.Error = true;
      state.putServices.Success = false;
      state.putServices.Loading = false;
    },
    [UploadServices.pending]: (state, action) => {
      state.uploadServices.Loading = true;
    },
    [UploadServices.fulfilled]: (state, action) => {
      state.uploadServices.Error = false;
      state.uploadServices.Success = true;
      state.uploadServices.Loading = false;
      state.uploadServices.data = action.payload;
    },
    [UploadServices.rejected]: (state, action) => {
      state.uploadServices.Error = true;
      state.uploadServices.Success = false;
      state.uploadServices.Loading = false;
    },
    [PutServicesUpload.pending]: (state, action) => {
      state.uploadPutServices.Loading = true;
    },
    [PutServicesUpload.fulfilled]: (state, action) => {
      state.uploadPutServices.Error = false;
      state.uploadPutServices.Success = true;
      state.uploadPutServices.Loading = false;
      state.uploadPutServices.data = action.payload;
    },
    [PutServicesUpload.rejected]: (state, action) => {
      state.uploadPutServices.Error = true;
      state.uploadPutServices.Success = false;
      state.uploadPutServices.Loading = false;
    },
  },
});

export default ServicesSlice.reducer;
