import React, { useState } from "react";
import GetRequest from "./get";
import DeleteRequest from "./delete";
import { useDispatch } from "react-redux";
import { RequestDelete, RequestGet } from "../../redux/requests";

function RequestsComp() {
  const [modal, setModal] = useState(false);
  const [id, setId] = useState();
  const dispatch = useDispatch();
  const HandelOpen = (e) => {
    setId(e.target.id);
    setModal(true);
  };
  const HandelClose = (e) => {
    setModal(false);
  };
  const HandelDelete = async (e) => {
    e.preventDefault();
    await dispatch(RequestDelete(id));
    dispatch(RequestGet());
    HandelClose();
  };
  return (
    <>
      <GetRequest HandelOpen={HandelOpen} />
      <DeleteRequest
        HandleDelete={HandelDelete}
        openDelete={modal}
        handleCloseDelete={HandelClose}
      />
    </>
  );
}

export default RequestsComp;
