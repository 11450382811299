import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../../utils/api";

export const PostSubservices = createAsyncThunk("Subservicess/post", async (body) => {
  return axios.post(`${API_URL}/sub_services`, body).then((res) => res);
});
export const GetSubservicess = createAsyncThunk("Subservicess/get", async () => {
  return await axios
    .get(`${API_URL}/sub_services`)
    .then((response) => response.data);
});
export const DeleteSubservices = createAsyncThunk(
  "Subservicess/delete",
  async (id) => {
    return await axios
      .delete(`${API_URL}/sub_services/${id}`)
      .then((response) => response.data);
  }
);
export const PutSubservices = createAsyncThunk(
  "Subservicess/put",
  async ({ body, id }) => {
    return await axios
      .put(`${API_URL}/sub_services/${id}`, body)
      .then((response) => console.log(response));
  }
);
export const UploadSubservices = createAsyncThunk(
  "Subservicess/upload",
  async (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("upload_preset", "br3m8rzo");
    try {
      return await axios
        .post("https://api.cloudinary.com/v1_1/dibvlfm8v/upload", formData)
        .then((response) => response?.data.secure_url);
    } catch (error) {
      return error;
    }
  }
);
export const UploadSubservices1 = createAsyncThunk(
  "Subservicess/upload1",
  async (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("upload_preset", "br3m8rzo");
    try {
      return await axios
        .post("https://api.cloudinary.com/v1_1/dibvlfm8v/upload", formData)
        .then((response) => response?.data.secure_url);
    } catch (error) {
      return error;
    }
  }
);
export const UploadSubservices2 = createAsyncThunk(
  "Subservicess/upload2",
  async (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("upload_preset", "br3m8rzo");
    try {
      return await axios
        .post("https://api.cloudinary.com/v1_1/dibvlfm8v/upload", formData)
        .then((response) => response?.data.secure_url);
    } catch (error) {
      return error;
    }
  }
);
export const PutSubservicesUpload = createAsyncThunk(
  "Subservicess/uploadPut",
  async (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("upload_preset", "br3m8rzo");
    try {
      return await axios
        .post("https://api.cloudinary.com/v1_1/dibvlfm8v/upload", formData)
        .then((response) => response?.data.secure_url);
    } catch (error) {
      return error;
    }
  }
);
export const PutSubservicesUpload1 = createAsyncThunk(
  "Subservicess/uploadPut1",
  async (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("upload_preset", "br3m8rzo");
    try {
      return await axios
        .post("https://api.cloudinary.com/v1_1/dibvlfm8v/upload", formData)
        .then((response) => response?.data.secure_url);
    } catch (error) {
      return error;
    }
  }
);
export const PutSubservicesUpload2 = createAsyncThunk(
  "Subservicess/uploadPut2",
  async (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("upload_preset", "br3m8rzo");
    try {
      return await axios
        .post("https://api.cloudinary.com/v1_1/dibvlfm8v/upload", formData)
        .then((response) => response?.data.secure_url);
    } catch (error) {
      return error;
    }
  }
);
const SubservicessSlice = createSlice({
  name: "Subservicess",
  initialState: {
    postSubservices: {
      Error: false,
      Loading: false,
      Success: false,
    },
    getSubservicess: {
      Error: false,
      Loading: false,
      Success: false,
      Data: [],
    },
    deleteSubservices: {
      Error: false,
      Loading: false,
      Success: false,
    },
    putSubservices: {
      Error: false,
      Loading: false,
      Success: false,
    },
    uploadSubservices: {
      Error: false,
      Loading: false,
      Success: false,
      data: "",
    },
    uploadSubservices1: {
      Error: false,
      Loading: false,
      Success: false,
      data: "",
    },
    uploadSubservices2: {
      Error: false,
      Loading: false,
      Success: false,
      data: "",
    },
    uploadPutSubservices: {
      Error: false,
      Loading: false,
      Success: false,
      data: "",
    },
    uploadPutSubservices1: {
      Error: false,
      Loading: false,
      Success: false,
      data: "",
    },
    uploadPutSubservices2: {
      Error: false,
      Loading: false,
      Success: false,
      data: "",
    },
  },
  extraReducers: {
    [PostSubservices.pending]: (state, action) => {
      state.postSubservices.Loading = true;
    },
    [PostSubservices.fulfilled]: (state, action) => {
      state.postSubservices.Error = false;
      state.postSubservices.Success = true;
      state.postSubservices.Loading = false;
    },
    [PostSubservices.rejected]: (state, action) => {
      state.postSubservices.Error = true;
      state.postSubservices.Success = false;
      state.postSubservices.Loading = false;
    },
    [GetSubservicess.pending]: (state, action) => {
      state.getSubservicess.Loading = true;
    },
    [GetSubservicess.fulfilled]: (state, action) => {
      state.getSubservicess.Error = false;
      state.getSubservicess.Success = true;
      state.getSubservicess.Loading = false;
      state.getSubservicess.Data = action.payload;
    },
    [GetSubservicess.rejected]: (state, action) => {
      state.getSubservicess.Error = true;
      state.getSubservicess.Success = false;
      state.getSubservicess.Loading = false;
      state.getSubservicess.Data = [];
    },
    [DeleteSubservices.pending]: (state, action) => {
      state.deleteSubservices.Loading = true;
    },
    [DeleteSubservices.fulfilled]: (state, action) => {
      state.deleteSubservices.Error = false;
      state.deleteSubservices.Success = true;
      state.deleteSubservices.Loading = false;
    },
    [DeleteSubservices.rejected]: (state, action) => {
      state.deleteSubservices.Error = true;
      state.deleteSubservices.Success = false;
      state.deleteSubservices.Loading = false;
    },
    [PutSubservices.pending]: (state, action) => {
      state.putSubservices.Loading = true;
    },
    [PutSubservices.fulfilled]: (state, action) => {
      state.putSubservices.Error = false;
      state.putSubservices.Success = true;
      state.putSubservices.Loading = false;
    },
    [PutSubservices.rejected]: (state, action) => {
      state.putSubservices.Error = true;
      state.putSubservices.Success = false;
      state.putSubservices.Loading = false;
    },
    [UploadSubservices.pending]: (state, action) => {
      state.uploadSubservices.Loading = true;
    },
    [UploadSubservices.fulfilled]: (state, action) => {
      state.uploadSubservices.Error = false;
      state.uploadSubservices.Success = true;
      state.uploadSubservices.Loading = false;
      state.uploadSubservices.data = action.payload;
    },
    [UploadSubservices.rejected]: (state, action) => {
      state.uploadSubservices.Error = true;
      state.uploadSubservices.Success = false;
      state.uploadSubservices.Loading = false;
    },
    [PutSubservicesUpload.pending]: (state, action) => {
      state.uploadPutSubservices.Loading = true;
    },
    [PutSubservicesUpload.fulfilled]: (state, action) => {
      state.uploadPutSubservices.Error = false;
      state.uploadPutSubservices.Success = true;
      state.uploadPutSubservices.Loading = false;
      state.uploadPutSubservices.data = action.payload;
    },
    [PutSubservicesUpload.rejected]: (state, action) => {
      state.uploadPutSubservices.Error = true;
      state.uploadPutSubservices.Success = false;
      state.uploadPutSubservices.Loading = false;
    },
    [UploadSubservices1.pending]: (state, action) => {
      state.uploadSubservices1.Loading = true;
    },
    [UploadSubservices1.fulfilled]: (state, action) => {
      state.uploadSubservices1.Error = false;
      state.uploadSubservices1.Success = true;
      state.uploadSubservices1.Loading = false;
      state.uploadSubservices1.data = action.payload;
    },
    [UploadSubservices1.rejected]: (state, action) => {
      state.uploadSubservices1.Error = true;
      state.uploadSubservices1.Success = false;
      state.uploadSubservices1.Loading = false;
    },
    [UploadSubservices2.pending]: (state, action) => {
      state.uploadSubservices2.Loading = true;
    },
    [UploadSubservices2.fulfilled]: (state, action) => {
      state.uploadSubservices2.Error = false;
      state.uploadSubservices2.Success = true;
      state.uploadSubservices2.Loading = false;
      state.uploadSubservices2.data = action.payload;
    },
    [UploadSubservices2.rejected]: (state, action) => {
      state.uploadSubservices2.Error = true;
      state.uploadSubservices2.Success = false;
      state.uploadSubservices2.Loading = false;
    },
    [PutSubservicesUpload1.pending]: (state, action) => {
      state.uploadPutSubservices1.Loading = true;
    },
    [PutSubservicesUpload1.fulfilled]: (state, action) => {
      state.uploadPutSubservices1.Error = false;
      state.uploadPutSubservices1.Success = true;
      state.uploadPutSubservices1.Loading = false;
      state.uploadPutSubservices1.data = action.payload;
    },
    [PutSubservicesUpload1.rejected]: (state, action) => {
      state.uploadPutSubservices1.Error = true;
      state.uploadPutSubservices1.Success = false;
      state.uploadPutSubservices1.Loading = false;
    },
    [PutSubservicesUpload2.pending]: (state, action) => {
      state.uploadPutSubservices2.Loading = true;
    },
    [PutSubservicesUpload2.fulfilled]: (state, action) => {
      state.uploadPutSubservices2.Error = false;
      state.uploadPutSubservices2.Success = true;
      state.uploadPutSubservices2.Loading = false;
      state.uploadPutSubservices2.data = action.payload;
    },
    [PutSubservicesUpload2.rejected]: (state, action) => {
      state.uploadPutSubservices2.Error = true;
      state.uploadPutSubservices2.Success = false;
      state.uploadPutSubservices2.Loading = false;
    },
  },
});

export default SubservicessSlice.reducer;
