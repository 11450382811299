import ModalCommon from "../../common/Modal/Modal";
import { useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CategoryPut, CategoryGet } from "../../../redux/category";
import CommonBtn from "../../common/CommonBtn";
import { Row, Col } from "react-grid-system";
import styled from "./style.module.css";

function PutCategory({ openPut, handleClosePut, put_id }) {
  const ids = put_id;
  const dispatch = useDispatch();
  const titleUz = useRef();
  const titleRu = useRef();
  const titleEn = useRef();
  const categoryPuts = useSelector((state) => state.category);
  const categoryGets = useSelector((state) => state.category.categoryGet.data);
  useEffect(() => {
    dispatch(CategoryGet());
  }, []);
  const HandleSubmit = async (e) => {
    e.preventDefault();
    const body = {
      category_name_uz: titleUz.current.value,
      category_name_ru: titleRu.current.value,
      category_name_en: titleEn.current.value,
    };
    await dispatch(CategoryPut({ body, id: ids }));
    dispatch(CategoryGet());
    window.location.reload();
  };
  return (
    <>
      <ModalCommon width={550} open={openPut} handleClose={handleClosePut}>
        <form className={styled.Wrapper} onSubmit={HandleSubmit}>
          <h3>Изменение категории</h3>
          <div className={styled.input_wrap}>
            <div className={styled.scrool}>
              <Row className={styled.row}>
                {categoryGets.map((elem) =>
                  elem.id == put_id ? (
                    <>
                      <Col className={styled.col} lg={6}>
                        <input
                          type="text"
                          placeholder={elem.category_name_uz}
                          ref={titleUz}
                          defaultValue={elem.category_name_uz}
                        />
                      </Col>
                      <Col className={styled.col} lg={6}>
                        <input
                          type="text"
                          placeholder={elem.category_name_ru}
                          ref={titleRu}
                          defaultValue={elem.category_name_ru}
                        />
                      </Col>
                      <Col className={styled.col} lg={12}>
                        <input
                          type="text"
                          placeholder={elem.category_name_en}
                          ref={titleEn}
                          defaultValue={elem.category_name_en}
                        />
                      </Col>
                    </>
                  ) : null
                )}
              </Row>
              <CommonBtn
                type="submit"
                style={{
                  margin: "20px auto 0 auto",
                  padding: "12px 40px",
                  border: "2px solid #fff",
                }}
              >
                Изменение
              </CommonBtn>
            </div>
          </div>
        </form>
      </ModalCommon>
    </>
  );
}
export default PutCategory;
