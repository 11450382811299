import React, { useEffect, useState } from "react";
import CategoryPost from "./post";
import CategoryAddForm from "./post";
import { useDispatch } from "react-redux";
import { CategoryGet } from "../../redux/category";
import GetCategory from "./get";
import PutCategory from "./put";
import DeleteCategory from "./delete";

function CategoryComp({ modal, handleClose }) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(CategoryGet());
  }, []);
  const [put, setPut] = useState(false);
  const [put_id, setPut_Id] = useState();
  const HandelOpenPut = (e) => {
    setPut_Id(e.target.id);
    setPut(true);
  };
  const HandelClosePut = () => setPut(false);

  const [del, setdel] = useState(false);
  const [del_id, setdel_Id] = useState();
  const HandelOpendel = (e) => {
    setdel_Id(e.target.id);
    setdel(true);
  };
  const HandelCloseDel = () => setPut(false);
  return (
    <>
      <CategoryAddForm modal={modal} HandelClose={handleClose} />
      <GetCategory onClickPut={HandelOpenPut} onClickDelete={HandelOpendel} />
      <PutCategory
        openPut={put}
        handleClosePut={HandelClosePut}
        put_id={put_id}
      />
      <DeleteCategory
        openDelete={del}
        handleCloseDelete={HandelCloseDel}
        del_id={del_id}
      />
    </>
  );
}

export default CategoryComp;
