import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { Button, Tooltip } from "antd";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UsergroupAddOutlined,
  UserAddOutlined,
  AppstoreOutlined,
  ShoppingCartOutlined,
  FileImageOutlined,
  LoginOutlined,
  CommentOutlined,
  BuildOutlined,
  QuestionCircleOutlined,
  UserOutlined,
  MessageOutlined,
  AppstoreAddOutlined,
  SettingOutlined,
  GatewayOutlined,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { NavLink, useNavigate } from "react-router-dom";
import { Layout, Menu, theme } from "antd";
import "./sidebar.css";
import logo from "../../assets/image/logos.png";
import { adminGet } from "../../redux/admin_add/index";
const { Header, Sider, Content } = Layout;

const Sidebar = ({ children }) => {
  const text = <span>Выйти</span>;
  const dispatch = useDispatch();
  const adminGetState = useSelector((state) => state.adminadd);
  const rows = adminGetState.userGet?.data;
  useEffect(() => {
    dispatch(adminGet());
  }, []);
  const [collapsed, setCollapsed] = useState(false);
  const cookies = new Cookies();
  const pathname = useLocation();
  const navigate = useNavigate();
  const HandleLogout = () => {
    cookies.remove("token");
    setTimeout(() => {
      navigate("/");
      window.location.reload();
    }, "1500");
  };
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <Layout style={{ height: "100vh" }}>
      <Sider
        style={{ backgroundColor: "#5A79E5" }}
        width={240}
        trigger={null}
        collapsible
        collapsed={collapsed}
      >
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={["1"]}
          items={[
            {
              key: "1",
              icon: <UserAddOutlined />,
              label: (
                <NavLink
                  to={"/adminadd"}
                  className={pathname == "/adminadd" ? "active" : ""}
                >
                  <span class="title">Админ</span>
                </NavLink>
              ),
            },
            {
              key: "2",
              icon: <MessageOutlined />,
              label: (
                <NavLink
                  to={"/requests"}
                  className={pathname == "/requests" ? "active" : ""}
                >
                  <span class="title">Заявки</span>
                </NavLink>
              ),
            },
            {
              key: "3",
              icon: <AppstoreAddOutlined />,
              label: (
                <NavLink
                  to={"/category"}
                  className={pathname == "/category" ? "active" : ""}
                >
                  <span class="title">Категории</span>
                </NavLink>
              ),
            },
            {
              key: "4",
              icon: <SettingOutlined />,
              label: (
                <NavLink
                  to={"/services"}
                  className={pathname == "/services" ? "active" : ""}
                >
                  <span class="title">Услуги</span>
                </NavLink>
              ),
            },
            {
              key: "6",
              icon: <AppstoreOutlined />,
              label: (
                <NavLink
                  to={"/products"}
                  className={pathname == "/products" ? "active" : ""}
                >
                  <span class="title">Продукты</span>
                </NavLink>
              ),
            },
            {
              key: "7",
              icon: <BuildOutlined />,
              label: (
                <NavLink
                  to={"/subservices"}
                  className={pathname == "/subservices" ? "active" : ""}
                >
                  <span class="title">Подуслуги</span>
                </NavLink>
              ),
            },
            {
              key: "8",
              icon: <GatewayOutlined />,
              label: (
                <NavLink
                  to={"/blog"}
                  className={pathname == "/blog" ? "active" : ""}
                >
                  <span class="title">Блог</span>
                </NavLink>
              ),
            },
          ]}
          style={{ backgroundColor: " #2A7D2E" }}
        />
      </Sider>
      <Layout className="site-layout">
        <Header
          style={{
            padding: 0,
            background: "#2A7D2E",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div className="logos">
            {React.createElement(
              collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
              {
                className: "trigger",
                onClick: () => setCollapsed(!collapsed),
              }
            )}
            <img src={logo} alt="" />
          </div>
          <div className="left">
            <div className="profile">
              <div className="profile-right">
                {rows.slice(0, 1).map((elem) => (
                  <>
                    <p className="profile_name">{elem.name}</p>
                    <p className="profile_email">{elem.email}</p>
                  </>
                ))}
              </div>
              <UserOutlined className="user-icon" />
              <Tooltip title={text}>
                <button onClick={HandleLogout}>
                  <LoginOutlined />
                </button>
              </Tooltip>
            </div>
          </div>
        </Header>
        <Content
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: 280,
            background: "#EEEFF5",
            overflowY: "auto",
            height: "85vh",
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};
export default Sidebar;
