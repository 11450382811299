import React, { useState } from "react";
import Sidebar from "../../components/sidebar";
import { WrapperContainer } from "../../style-App";
import HeaderTopCommon from "../../components/common/HeaderTop";
import CategoryComp from "../../components/category";

function Category() {
  const [modal, setModal] = useState(false);
  const handleClose = () => setModal(false);
  const handleOpen = () => setModal(true);
  return (
    <>
      <Sidebar>
        <WrapperContainer>
          <HeaderTopCommon
            title={"Категории"}
            textBtn={"Добавить категорию"}
            onClick={handleOpen}
          />
          <CategoryComp modal={modal} handleClose={handleClose} />
        </WrapperContainer>
      </Sidebar>
    </>
  );
}

export default Category;
