import React, { useRef, useEffect, useState } from "react";
import CommonBtn from "../../common/CommonBtn";
import ModalCommon from "../../common/Modal/Modal";
import { useDispatch } from "react-redux";
import { CategoryAdd, CategoryGet } from "../../../redux/category";
import { useSelector } from "react-redux";
import { Row, Col } from "react-grid-system";
import styled from "./style.module.css";
import { Spin } from "antd";
import {
  GetProductss,
  PostProducts,
  UploadProducts,
  UploadProducts1,
  UploadProducts2,
} from "../../../redux/products";
import SelectCommon from "../../common/select";

function ProductAddForm({ modal, handleClose }) {
  const dispatch = useDispatch();
  const titleUz = useRef();
  const titleRu = useRef();
  const titleEn = useRef();
  const DecEn = useRef();
  const DecUz = useRef();
  const DecRu = useRef();
  const [categoryId, setCategoryId] = useState();

  const dataImage = useSelector((state) => state.products.uploadProducts.data);
  const dataImage1 = useSelector(
    (state) => state.products.uploadProducts1.data
  );
  const dataImage2 = useSelector(
    (state) => state.products.uploadProducts2.data
  );

  const HandelOnChange = (e) => {
    dispatch(UploadProducts(e));
  };
  const HandelOnChange1 = (e) => {
    dispatch(UploadProducts1(e));
  };
  const HandelOnChange2 = (e) => {
    dispatch(UploadProducts2(e));
  };

  useEffect(() => {
    dispatch(CategoryGet());
  }, []);
  const category = useSelector((state) => state.category.categoryGet.data);
  const options = [];
  category.map((elem) =>
    options.push({
      value: elem.id,
      label: elem.category_name_ru,
    })
  );

  const HandleSubmit = async (e) => {
    e.preventDefault();
    const body = {
      title_uz: titleUz.current.value,
      title_ru: titleRu.current.value,
      title_en: titleEn.current.value,
      description_uz: DecUz.current.value,
      description_en: DecEn.current.value,
      description_ru: DecRu.current.value,
      img1: dataImage,
      img2: dataImage1,
      img3: dataImage2,
      category: categoryId,
    };
    await dispatch(PostProducts(body));
    dispatch(GetProductss());
    window.location.reload();
  };
  console.log(categoryId);
  const Loading = useSelector((state) => state.products.uploadProducts.Loading);
  const Loading1 = useSelector(
    (state) => state.products.uploadProducts1.Loading
  );
  const Loading2 = useSelector(
    (state) => state.products.uploadProducts2.Loading
  );
  const Success = useSelector((state) => state.products.uploadProducts.Success);
  const Success1 = useSelector(
    (state) => state.products.uploadProducts1.Success
  );
  const Success2 = useSelector(
    (state) => state.products.uploadProducts2.Success
  );
  return (
    <ModalCommon width={550} open={modal} handleClose={handleClose}>
      <>
        <form onSubmit={HandleSubmit}>
          <div className={styled.Wrapper}>
            <h3>Добавить продукт</h3>
            <div className={styled.input_wrap}>
              <div className={styled.scrool}>
                <Row className={styled.row}>
                  <Col className={styled.col} lg={6}>
                    {Success ? (
                      <>
                        <div className={styled.spinsSecond}>
                          <ion-icon name="checkmark-outline"></ion-icon>
                        </div>
                      </>
                    ) : (
                      <>
                        {Loading ? (
                          <div className={styled.spins}>
                            <Spin size="large" />
                          </div>
                        ) : (
                          <>
                            <input
                              type="file"
                              id="file"
                              onChange={HandelOnChange}
                            />
                            <label for="file" class="custom-file-upload">
                              <span className={styled.spandownload}>
                                <ion-icon name="cloud-download-outline"></ion-icon>
                              </span>
                            </label>
                          </>
                        )}
                      </>
                    )}
                  </Col>
                  <Col className={styled.col} lg={6}>
                    {Success1 ? (
                      <>
                        <div className={styled.spinsSecond}>
                          <ion-icon name="checkmark-outline"></ion-icon>
                        </div>
                      </>
                    ) : (
                      <>
                        {Loading1 ? (
                          <div className={styled.spins}>
                            <Spin size="large" />
                          </div>
                        ) : (
                          <>
                            <input
                              type="file"
                              id="file"
                              onChange={HandelOnChange1}
                            />
                            <label for="file" class="custom-file-upload">
                              <span className={styled.spandownload}>
                                <ion-icon name="cloud-download-outline"></ion-icon>
                              </span>
                            </label>
                          </>
                        )}
                      </>
                    )}
                  </Col>
                  <Col className={styled.cols} lg={12}>
                    {Success2 ? (
                      <>
                        <div className={styled.spinsSecond}>
                          <ion-icon name="checkmark-outline"></ion-icon>
                        </div>
                      </>
                    ) : (
                      <>
                        {Loading2 ? (
                          <div className={styled.spins}>
                            <Spin size="large" />
                          </div>
                        ) : (
                          <>
                            <input
                              type="file"
                              id="file"
                              onChange={HandelOnChange2}
                            />
                            <label for="file" class="custom-file-upload">
                              <span className={styled.spandownload}>
                                <ion-icon name="cloud-download-outline"></ion-icon>
                              </span>
                            </label>
                          </>
                        )}
                      </>
                    )}
                  </Col>
                  <Col className={styled.col} lg={6}>
                    <input
                      type="text"
                      placeholder="продукт узб..."
                      required
                      ref={titleUz}
                    />
                  </Col>
                  <Col className={styled.col} lg={6}>
                    <input
                      type="text"
                      placeholder="продукт русский..."
                      required
                      ref={titleRu}
                    />
                  </Col>
                  <Col className={styled.col} lg={12}>
                    <input
                      type="text"
                      placeholder="продукт англ..."
                      required
                      ref={titleEn}
                    />
                  </Col>

                  {/* //des */}
                  <Col className={styled.col} lg={6}>
                    <input
                      type="text"
                      placeholder="описание продукт узб..."
                      required
                      ref={DecUz}
                    />
                  </Col>
                  <Col className={styled.col} lg={6}>
                    <input
                      type="text"
                      placeholder="описание продукт русский..."
                      required
                      ref={DecRu}
                    />
                  </Col>
                  <Col className={styled.col} lg={12}>
                    <input
                      type="text"
                      placeholder="описание продукт англ..."
                      required
                      ref={DecEn}
                    />
                  </Col>
                  <Col lg={12}>
                    <div className="selects">
                      <SelectCommon
                        onChange={(e) => setCategoryId(e)}
                        placeholder="Select"
                        options={options}
                      />
                    </div>
                  </Col>
                </Row>
                <CommonBtn
                  type="submit"
                  style={{
                    margin: "20px auto 0 auto",
                    padding: "12px 40px",
                    border: "2px solid #fff",
                  }}
                >
                  Добавить
                </CommonBtn>
              </div>
            </div>
          </div>
        </form>
      </>
    </ModalCommon>
  );
}

export default ProductAddForm;
