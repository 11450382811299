import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./login/authSlice";
import AdminSlice from "./admin_add/index";
import RequestSlice from "./requests/index";
import CategorySlice from "./category/index";
import ServicesSlice from "./services/index";
import ProductssSlice from "./products/index";
import SubservicessSlice from "./subservices/index";
import BlogSlice from "./Blog/index";

export const store = configureStore({
  reducer: {
    admin: authSlice,
    adminadd: AdminSlice,
    requests: RequestSlice,
    category: CategorySlice,
    services: ServicesSlice,
    products: ProductssSlice,
    subservices: SubservicessSlice,
    blog: BlogSlice,
  },
});
