import React, { useRef } from "react";
import ModalCommon from "../../common/Modal/Modal";
import styled from "./style.module.css";
import { Col, Row } from "react-grid-system";
import CommonBtn from "../../common/CommonBtn";
import { Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  GetServices,
  PostService,
  UploadServices,
} from "../../../redux/services";

function ServicesPost({ modal, handelClose }) {
  const titleUz = useRef();
  const titleEn = useRef();
  const titleRu = useRef();
  const dispatch = useDispatch();
  const dataImage = useSelector((state) => state.services.uploadServices.data);
  const HandelOnChange = (e) => {
    dispatch(UploadServices(e));
  };
  const HandleSubmit = async (e) => {
    e.preventDefault();
    const body = {
      services_name_uz: titleUz.current.value,
      services_name_en: titleEn.current.value,
      services_name_ru: titleRu.current.value,
      image: dataImage,
    };
    await dispatch(PostService(body));
    dispatch(GetServices());
    window.location.reload();
  };

  const Loading = useSelector((state) => state.services.uploadServices.Loading);
  const Success = useSelector((state) => state.services.uploadServices.Success);
  return (
    <>
      <ModalCommon open={modal} handleClose={handelClose}>
        <form onSubmit={HandleSubmit} className={styled.Wrapper}>
          <h3>Добавить категорию</h3>
          <div className={styled.input_wrap}>
            <div className={styled.scrool}>
              <Row className={styled.row}>
                <Col className={styled.col} lg={12}>
                  {Success ? (
                    <>
                      <div className={styled.spinsSecond}>
                        <ion-icon name="checkmark-outline"></ion-icon>
                      </div>
                    </>
                  ) : (
                    <>
                      {Loading ? (
                        <div className={styled.spins}>
                          <Spin size="large" />
                        </div>
                      ) : (
                        <>
                          <input
                            type="file"
                            id="file"
                            onChange={HandelOnChange}
                          />
                          <label for="file" class="custom-file-upload">
                            <span className={styled.spandownload}>
                              <ion-icon name="cloud-download-outline"></ion-icon>
                            </span>
                          </label>
                        </>
                      )}
                    </>
                  )}
                </Col>
                <Col className={styled.cols} lg={6}>
                  <input
                    type="text"
                    placeholder="Услуги узб..."
                    required
                    ref={titleUz}
                  />
                </Col>
                <Col className={styled.cols} lg={6}>
                  <input
                    type="text"
                    placeholder="Услуги русский..."
                    required
                    ref={titleRu}
                  />
                </Col>
                <Col className={styled.col} lg={12}>
                  <input
                    type="text"
                    placeholder="Услуги англ..."
                    required
                    ref={titleEn}
                  />
                </Col>
              </Row>
              <CommonBtn
                type="submit"
                style={{
                  margin: "20px auto 0 auto",
                  padding: "12px 40px",
                  border: "2px solid #fff",
                }}
              >
                Добавить
              </CommonBtn>
            </div>
          </div>
        </form>
      </ModalCommon>
    </>
  );
}

export default ServicesPost;
