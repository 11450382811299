import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../../utils/api";

export const PostProducts = createAsyncThunk("Productss/post", async (body) => {
  return axios.post(`${API_URL}/products`, body).then((res) => res);
});
export const GetProductss = createAsyncThunk("Productss/get", async () => {
  return await axios
    .get(`${API_URL}/products`)
    .then((response) => response.data);
});
export const DeleteProducts = createAsyncThunk(
  "Productss/delete",
  async (id) => {
    return await axios
      .delete(`${API_URL}/products/${id}`)
      .then((response) => response.data);
  }
);
export const PutProducts = createAsyncThunk(
  "Productss/put",
  async ({ body, id }) => {
    return await axios
      .put(`${API_URL}/products/${id}`, body)
      .then((response) => console.log(response));
  }
);
export const UploadProducts = createAsyncThunk(
  "Productss/upload",
  async (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("upload_preset", "br3m8rzo");
    try {
      return await axios
        .post("https://api.cloudinary.com/v1_1/dibvlfm8v/upload", formData)
        .then((response) => response?.data.secure_url);
    } catch (error) {
      return error;
    }
  }
);
export const UploadProducts1 = createAsyncThunk(
  "Productss/upload1",
  async (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("upload_preset", "br3m8rzo");
    try {
      return await axios
        .post("https://api.cloudinary.com/v1_1/dibvlfm8v/upload", formData)
        .then((response) => response?.data.secure_url);
    } catch (error) {
      return error;
    }
  }
);
export const UploadProducts2 = createAsyncThunk(
  "Productss/upload2",
  async (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("upload_preset", "br3m8rzo");
    try {
      return await axios
        .post("https://api.cloudinary.com/v1_1/dibvlfm8v/upload", formData)
        .then((response) => response?.data.secure_url);
    } catch (error) {
      return error;
    }
  }
);
export const PutProductsUpload = createAsyncThunk(
  "Productss/uploadPut",
  async (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("upload_preset", "br3m8rzo");
    try {
      return await axios
        .post("https://api.cloudinary.com/v1_1/dibvlfm8v/upload", formData)
        .then((response) => response?.data.secure_url);
    } catch (error) {
      return error;
    }
  }
);
export const PutProductsUpload1 = createAsyncThunk(
  "Productss/uploadPut1",
  async (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("upload_preset", "br3m8rzo");
    try {
      return await axios
        .post("https://api.cloudinary.com/v1_1/dibvlfm8v/upload", formData)
        .then((response) => response?.data.secure_url);
    } catch (error) {
      return error;
    }
  }
);
export const PutProductsUpload2 = createAsyncThunk(
  "Productss/uploadPut2",
  async (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("upload_preset", "br3m8rzo");
    try {
      return await axios
        .post("https://api.cloudinary.com/v1_1/dibvlfm8v/upload", formData)
        .then((response) => response?.data.secure_url);
    } catch (error) {
      return error;
    }
  }
);
const ProductssSlice = createSlice({
  name: "Productss",
  initialState: {
    postProducts: {
      Error: false,
      Loading: false,
      Success: false,
    },
    getProductss: {
      Error: false,
      Loading: false,
      Success: false,
      Data: [],
    },
    deleteProducts: {
      Error: false,
      Loading: false,
      Success: false,
    },
    putProducts: {
      Error: false,
      Loading: false,
      Success: false,
    },
    uploadProducts: {
      Error: false,
      Loading: false,
      Success: false,
      data: "",
    },
    uploadProducts1: {
      Error: false,
      Loading: false,
      Success: false,
      data: "",
    },
    uploadProducts2: {
      Error: false,
      Loading: false,
      Success: false,
      data: "",
    },
    uploadPutProducts: {
      Error: false,
      Loading: false,
      Success: false,
      data: "",
    },
    uploadPutProducts1: {
      Error: false,
      Loading: false,
      Success: false,
      data: "",
    },
    uploadPutProducts2: {
      Error: false,
      Loading: false,
      Success: false,
      data: "",
    },
  },
  extraReducers: {
    [PostProducts.pending]: (state, action) => {
      state.postProducts.Loading = true;
    },
    [PostProducts.fulfilled]: (state, action) => {
      state.postProducts.Error = false;
      state.postProducts.Success = true;
      state.postProducts.Loading = false;
    },
    [PostProducts.rejected]: (state, action) => {
      state.postProducts.Error = true;
      state.postProducts.Success = false;
      state.postProducts.Loading = false;
    },
    [GetProductss.pending]: (state, action) => {
      state.getProductss.Loading = true;
    },
    [GetProductss.fulfilled]: (state, action) => {
      state.getProductss.Error = false;
      state.getProductss.Success = true;
      state.getProductss.Loading = false;
      state.getProductss.Data = action.payload;
    },
    [GetProductss.rejected]: (state, action) => {
      state.getProductss.Error = true;
      state.getProductss.Success = false;
      state.getProductss.Loading = false;
      state.getProductss.Data = [];
    },
    [DeleteProducts.pending]: (state, action) => {
      state.deleteProducts.Loading = true;
    },
    [DeleteProducts.fulfilled]: (state, action) => {
      state.deleteProducts.Error = false;
      state.deleteProducts.Success = true;
      state.deleteProducts.Loading = false;
    },
    [DeleteProducts.rejected]: (state, action) => {
      state.deleteProducts.Error = true;
      state.deleteProducts.Success = false;
      state.deleteProducts.Loading = false;
    },
    [PutProducts.pending]: (state, action) => {
      state.putProducts.Loading = true;
    },
    [PutProducts.fulfilled]: (state, action) => {
      state.putProducts.Error = false;
      state.putProducts.Success = true;
      state.putProducts.Loading = false;
    },
    [PutProducts.rejected]: (state, action) => {
      state.putProducts.Error = true;
      state.putProducts.Success = false;
      state.putProducts.Loading = false;
    },
    [UploadProducts.pending]: (state, action) => {
      state.uploadProducts.Loading = true;
    },
    [UploadProducts.fulfilled]: (state, action) => {
      state.uploadProducts.Error = false;
      state.uploadProducts.Success = true;
      state.uploadProducts.Loading = false;
      state.uploadProducts.data = action.payload;
    },
    [UploadProducts.rejected]: (state, action) => {
      state.uploadProducts.Error = true;
      state.uploadProducts.Success = false;
      state.uploadProducts.Loading = false;
    },
    [PutProductsUpload.pending]: (state, action) => {
      state.uploadPutProducts.Loading = true;
    },
    [PutProductsUpload.fulfilled]: (state, action) => {
      state.uploadPutProducts.Error = false;
      state.uploadPutProducts.Success = true;
      state.uploadPutProducts.Loading = false;
      state.uploadPutProducts.data = action.payload;
    },
    [PutProductsUpload.rejected]: (state, action) => {
      state.uploadPutProducts.Error = true;
      state.uploadPutProducts.Success = false;
      state.uploadPutProducts.Loading = false;
    },
    [UploadProducts1.pending]: (state, action) => {
      state.uploadProducts1.Loading = true;
    },
    [UploadProducts1.fulfilled]: (state, action) => {
      state.uploadProducts1.Error = false;
      state.uploadProducts1.Success = true;
      state.uploadProducts1.Loading = false;
      state.uploadProducts1.data = action.payload;
    },
    [UploadProducts1.rejected]: (state, action) => {
      state.uploadProducts1.Error = true;
      state.uploadProducts1.Success = false;
      state.uploadProducts1.Loading = false;
    },
    [UploadProducts2.pending]: (state, action) => {
      state.uploadProducts2.Loading = true;
    },
    [UploadProducts2.fulfilled]: (state, action) => {
      state.uploadProducts2.Error = false;
      state.uploadProducts2.Success = true;
      state.uploadProducts2.Loading = false;
      state.uploadProducts2.data = action.payload;
    },
    [UploadProducts2.rejected]: (state, action) => {
      state.uploadProducts2.Error = true;
      state.uploadProducts2.Success = false;
      state.uploadProducts2.Loading = false;
    },
    [PutProductsUpload1.pending]: (state, action) => {
      state.uploadPutProducts1.Loading = true;
    },
    [PutProductsUpload1.fulfilled]: (state, action) => {
      state.uploadPutProducts1.Error = false;
      state.uploadPutProducts1.Success = true;
      state.uploadPutProducts1.Loading = false;
      state.uploadPutProducts1.data = action.payload;
    },
    [PutProductsUpload1.rejected]: (state, action) => {
      state.uploadPutProducts1.Error = true;
      state.uploadPutProducts1.Success = false;
      state.uploadPutProducts1.Loading = false;
    },
    [PutProductsUpload2.pending]: (state, action) => {
      state.uploadPutProducts2.Loading = true;
    },
    [PutProductsUpload2.fulfilled]: (state, action) => {
      state.uploadPutProducts2.Error = false;
      state.uploadPutProducts2.Success = true;
      state.uploadPutProducts2.Loading = false;
      state.uploadPutProducts2.data = action.payload;
    },
    [PutProductsUpload2.rejected]: (state, action) => {
      state.uploadPutProducts2.Error = true;
      state.uploadPutProducts2.Success = false;
      state.uploadPutProducts2.Loading = false;
    },
  },
});

export default ProductssSlice.reducer;
