import React from "react";
import RequestsComp from "../../components/requests";
import Sidebar from "../../components/sidebar";
import { WrapperContainer } from "../../style-App";
import HeaderTopCommon from "../../components/common/HeaderTop";
import TableAdd from "../../components/admin_add/table";

function Requests() {
  return (
    <>
      <Sidebar>
        <WrapperContainer>
          <HeaderTopCommon title={"Заявки"} />
          <RequestsComp />
        </WrapperContainer>
      </Sidebar>
    </>
  );
}

export default Requests;
