import React, { useRef, useEffect, useState } from "react";
import CommonBtn from "../../common/CommonBtn";
import ModalCommon from "../../common/Modal/Modal";
import { useDispatch } from "react-redux";
import { CategoryAdd, CategoryGet } from "../../../redux/category";
import { useSelector } from "react-redux";
import { Row, Col } from "react-grid-system";
import styled from "./style.module.css";
import { Spin } from "antd";
import SelectCommon from "../../common/select";
import {
  GetProductss,
  PutProducts,
  PutProductsUpload,
  PutProductsUpload1,
  PutProductsUpload2,
} from "../../../redux/products";

function ProductPutForm({ modal, handleClose, putId }) {
  const dispatch = useDispatch();
  const titleUz = useRef();
  const titleRu = useRef();
  const titleEn = useRef();
  const DecEn = useRef();
  const DecUz = useRef();
  const DecRu = useRef();
  const IdPut = putId;
  const [categoryId, setCategoryId] = useState();

  const dataImage = useSelector(
    (state) => state.products.uploadPutProducts.data
  );
  const dataImage1 = useSelector(
    (state) => state.products.uploadPutProducts1.data
  );
  const dataImage2 = useSelector(
    (state) => state.products.uploadPutProducts2.data
  );

  const HandelOnChange = (e) => {
    dispatch(PutProductsUpload(e));
  };
  const HandelOnChange1 = (e) => {
    dispatch(PutProductsUpload1(e));
  };
  const HandelOnChange2 = (e) => {
    dispatch(PutProductsUpload2(e));
  };

  useEffect(() => {
    dispatch(CategoryGet());
  }, []);
  const category = useSelector((state) => state.category.categoryGet.data);
  const options = [];
  category.map((elem) =>
    options.push({
      value: elem.id,
      label: elem.category_name_ru,
    })
  );

  const HandleSubmit = async (e) => {
    e.preventDefault();
    const body = {
      title_uz: titleUz.current.value,
      title_ru: titleRu.current.value,
      title_en: titleEn.current.value,
      description_uz: DecUz.current.value,
      description_en: DecEn.current.value,
      description_ru: DecRu.current.value,
      img1: dataImage,
      img2: dataImage1,
      img3: dataImage2,
      category: categoryId,
    };
    await dispatch(PutProducts({ body, id: IdPut }));
    dispatch(GetProductss());
    window.location.reload();
  };
  const Loading = useSelector(
    (state) => state.products.uploadPutProducts.Loading
  );
  const Loading1 = useSelector(
    (state) => state.products.uploadPutProducts1.Loading
  );
  const Loading2 = useSelector(
    (state) => state.products.uploadPutProducts2.Loading
  );
  const Success = useSelector(
    (state) => state.products.uploadPutProducts.Success
  );
  const Success1 = useSelector(
    (state) => state.products.uploadPutProducts1.Success
  );
  const Success2 = useSelector(
    (state) => state.products.uploadPutProducts2.Success
  );
  const data = useSelector((state) => state.products.getProductss.Data);
  return (
    <ModalCommon width={550} open={modal} handleClose={handleClose}>
      <>
        <form onSubmit={HandleSubmit}>
          <div className={styled.Wrapper}>
            <h3>Изменить продукт</h3>
            {data.map((elem) =>
              elem.id == putId ? (
                <>
                  <div className={styled.input_wrap}>
                    <div className={styled.scrool}>
                      <Row className={styled.row}>
                        <Col className={styled.col} lg={6}>
                          {Success ? (
                            <>
                              <div className={styled.spinsSecond}>
                                <ion-icon name="checkmark-outline"></ion-icon>
                              </div>
                            </>
                          ) : (
                            <>
                              {Loading ? (
                                <div className={styled.spins}>
                                  <Spin size="large" />
                                </div>
                              ) : (
                                <>
                                  <input
                                    type="file"
                                    id="lox"
                                    onChange={HandelOnChange}
                                  />
                                  <label for="lox" class="custom-file-upload">
                                    <span className={styled.spandownload}>
                                      <ion-icon name="cloud-download-outline"></ion-icon>
                                    </span>
                                  </label>
                                </>
                              )}
                            </>
                          )}
                        </Col>
                        <Col className={styled.col} lg={6}>
                          {Success1 ? (
                            <>
                              <div className={styled.spinsSecond}>
                                <ion-icon name="checkmark-outline"></ion-icon>
                              </div>
                            </>
                          ) : (
                            <>
                              {Loading1 ? (
                                <div className={styled.spins}>
                                  <Spin size="large" />
                                </div>
                              ) : (
                                <>
                                  <input
                                    type="file"
                                    id="file2"
                                    onChange={HandelOnChange1}
                                  />
                                  <label for="file2" class="custom-file-upload">
                                    <span className={styled.spandownload}>
                                      <ion-icon name="cloud-download-outline"></ion-icon>
                                    </span>
                                  </label>
                                </>
                              )}
                            </>
                          )}
                        </Col>
                        <Col className={styled.cols} lg={12}>
                          {Success2 ? (
                            <>
                              <div className={styled.spinsSecond}>
                                <ion-icon name="checkmark-outline"></ion-icon>
                              </div>
                            </>
                          ) : (
                            <>
                              {Loading2 ? (
                                <div className={styled.spins}>
                                  <Spin size="large" />
                                </div>
                              ) : (
                                <>
                                  <input
                                    type="file"
                                    id="file3"
                                    onChange={HandelOnChange2}
                                  />
                                  <label for="file3" class="custom-file-upload">
                                    <span className={styled.spandownload}>
                                      <ion-icon name="cloud-download-outline"></ion-icon>
                                    </span>
                                  </label>
                                </>
                              )}
                            </>
                          )}
                        </Col>
                        <Col className={styled.col} lg={6}>
                          <input
                            type="text"
                            placeholder={elem.title_uz}
                            defaultValue={elem.title_uz}
                            ref={titleUz}
                          />
                        </Col>
                        <Col className={styled.col} lg={6}>
                          <input
                            type="text"
                            placeholder={elem.title_ru}
                            defaultValue={elem.title_ru}
                            ref={titleRu}
                          />
                        </Col>
                        <Col className={styled.col} lg={12}>
                          <input
                            type="text"
                            placeholder={elem.title_en}
                            defaultValue={elem.title_en}
                            ref={titleEn}
                          />
                        </Col>

                        {/* //des */}
                        <Col className={styled.col} lg={6}>
                          <input
                            type="text"
                            placeholder={elem.description_uz}
                            defaultValue={elem.description_uz}
                            ref={DecUz}
                          />
                        </Col>
                        <Col className={styled.col} lg={6}>
                          <input
                            type="text"
                            placeholder={elem.description_ru}
                            defaultValue={elem.description_ru}
                            ref={DecRu}
                          />
                        </Col>
                        <Col className={styled.col} lg={12}>
                          <input
                            type="text"
                            placeholder={elem.description_en}
                            defaultValue={elem.description_en}
                            ref={DecEn}
                          />
                        </Col>
                        <Col lg={12}>
                          <h3>{elem.category?.category_name_ru}</h3>
                          <div className="selects">
                            <SelectCommon
                              onChange={(e) => setCategoryId(e)}
                              placeholder="Select"
                              options={options}
                            />
                          </div>
                        </Col>
                      </Row>
                      <CommonBtn
                        type="submit"
                        style={{
                          margin: "20px auto 0 auto",
                          padding: "12px 40px",
                          border: "2px solid #fff",
                        }}
                      >
                        Изменить
                      </CommonBtn>
                    </div>
                  </div>
                </>
              ) : null
            )}
          </div>
        </form>
      </>
    </ModalCommon>
  );
}

export default ProductPutForm;
